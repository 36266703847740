import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

//课堂数据
export function course(data) {
  return request({
    url: baseUrl + "/course",
    method: "get",
    params: data,
  });
}

//体育作业
export function homework(data) {
  return request({
    url: baseUrl + "/home/homework",
    method: "get",
    params: data,
  });
}
