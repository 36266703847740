<template>
  <div>
    <div class="qiehuan" @click="gb">
      <img style="width: 100%;height: 100%;" src="../assets/qiehuan.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse:false
    }
  },
  methods: {
    gb(){
      this.isCollapse =! this.isCollapse
      this.$store.commit('changeIsCollapse', this.isCollapse)
    }
  },
}
</script>

<style lang="less" scoped>
  .qiehuan{
    width: 26px;
    height: 20.32px;
    padding-top: 11px;
    padding-left: 20px;
  }
</style>